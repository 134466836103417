import {LoadingButton} from '@mui/lab';
import {
    Button,
    Card,
    Checkbox,
    CircularProgress,
    DialogActions,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import {PDFDownloadLink} from '@react-pdf/renderer';
import axios from 'axios';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {toWords} from 'number-to-words';
import React, {useEffect, useState} from 'react';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import Invoice from '../../../components/invoice/Invoice';
import useAuth from '../../../hooks/useAuth';
import {BILL_BUCKET} from '../../../utils/Constants';
import {CreatePostAPICall, UserProfile, envType} from '../../../utils/apis';
import {mid, mkey, paytmGateWayUrl} from '../../../utils/paytmGatewayInfo';
import {
    BootstrapDialog,
    BootstrapDialogTitle,
    GetPaymentOptions,
    SystemColor,
    uploadFileS3,
} from '../../../utils/utilities';

const Paytm = require('paytmchecksum');

export default function AccountAddAmount() {
    const {enqueueSnackbar} = useSnackbar();
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const [paymentData, setPaymentData] = useState(GetPaymentOptions);
    const [istermsAndConditionCheck, setIstermsAndConditionCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showBillModel, setShowBillModel] = useState(false);
    const [showApplyCoupon, setShowApplyCoupon] = useState(false);
    const [BillData, setBillData] = useState([]);
    const [couponCode, setCouponCode] = useState('');
    const [userCoupon, setUserCoupon] = useState([]);
    const [couponType, setCouponType] = useState('PERCENTAGE_OFF');
    const [isCouponUsed, setIsCouponUsed] = useState(false);
    const [couponAmount, setCouponAmount] = useState(0);
    const [userIdForValidation, setUserIdForValidation] = useState('');
    const [offerDate, setOfferDate] = useState('');

    const onResetCouponState = () => {
        setCouponCode('');
        setCouponType(null);
        setIsCouponUsed(false);
        setCouponAmount(0);
        setUserIdForValidation('');
        setOfferDate('');
    };

    const handleClose = () => {
        setShowBillModel(false);
    };

    const handleCloseApplyCoupon = () => {
        setShowApplyCoupon(false);
    };

    useEffect(() => {
    }, [showBillModel]);

    const onClickPayment = async () => {
        setLoading(true);
        const response = await CreatePostAPICall(enqueueSnackbar, UserProfile, {
            username: user?.email ? user.email : localUser,
            action: 'getUserInfo',
        });
        // console.log(response);
        const userID = response.data.body.Id;
        setUserIdForValidation(userID);
        const selectedRow = paymentData.find((x) => x.IsChecked);
        const userEmail = user?.email;

        let amount = selectedRow.PriceWithGST;

        if (couponType === 'PERCENTAGE_OFF' && selectedRow.OfferOnDiscountPrice > 0) {
            amount = selectedRow.finalGST;
        }
        if (couponType === 'FLAT_OFF' && selectedRow.OfferOnDiscountPrice > 0) {
            amount = selectedRow.finalGST;
        }
        const orderId = `IN_${userID}_${new Date().getTime()}`;
        setBillData([userEmail, orderId, selectedRow, response.data.body]);
        const paytmParams = {};

        paytmParams.body = {
            requestType: 'Payment',
            mid,
            websiteName: 'WEBSTAGING',
            orderId,
            callbackUrl: window.location.href,
            txnAmount: {
                value: amount,
                currency: 'INR',
            },
            userInfo: {
                custId: userEmail,
            },
        };
        // console.log(paytmParams.body);

        await Paytm.generateSignatureByString(JSON.stringify(paytmParams.body), mkey).then(async (checksum) => {
            const selectedRow = paymentData.find((x) => x.IsChecked);
            const amount = selectedRow.PriceWithGST;
            paytmParams.head = {
                signature: checksum,
            };

            const postData = JSON.stringify(paytmParams);

            const initiateTransactionRes = await axios({
                url: `${paytmGateWayUrl}/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData,
                method: 'post',
            });

            const config = {
                root: '',
                style: {
                    bodyBackgroundColor: '#fafafb',
                    bodyColor: '',
                    themeBackgroundColor: '#7635DC',
                    themeColor: '#ffffff',
                    headerBackgroundColor: '#284055',
                    headerColor: '#ffffff',
                    errorColor: '#eb4034',
                    successColor: '#0bf702',
                    card: {
                        padding: '',
                        backgroundColor: '',
                    },
                },
                data: {
                    orderId,
                    token: initiateTransactionRes.data.body.txnToken,
                    tokenType: 'TXN_TOKEN',
                    amount /* update amount */,
                },
                payMode: {
                    labels: {},
                    filter: {
                        exclude: [],
                    },
                    order: ['CC', 'DC', 'NB', 'UPI', 'PPBL', 'PPI', 'BALANCE', 'CARD'],
                },
                website: 'WEBSTAGING',
                flow: 'DEFAULT',
                merchant: {
                    mid,
                    redirect: false,
                },
                handler: {
                    transactionStatus: function transactionStatus(paymentStatus) {
                        if (paymentStatus.STATUS === 'TXN_SUCCESS') {
                            setBillData([userEmail, orderId, selectedRow, response.data.body, paymentStatus.TXNDATE, paymentStatus]);
                            enqueueSnackbar('Recharge Transaction Successful');
                            setShowBillModel(true);
                        } else {
                            setLoading(false);
                            enqueueSnackbar('Recharge Transaction Failed', {variant: 'error'});
                        }
                        if (window.Paytm && window.Paytm.CheckoutJS) {
                            window.Paytm.CheckoutJS.close();
                            setLoading(false);
                        }
                    },
                    notifyMerchant: await function notifyMerchant() {
                        setLoading(false);
                        if (window.Paytm && window.Paytm.CheckoutJS) {
                            // initialze configuration using init method
                            window.Paytm.CheckoutJS.close();
                        }
                    },
                },
            };

            if (window.Paytm && window.Paytm.CheckoutJS) {
                window.Paytm.CheckoutJS.init(config)
                    .then(async () => {
                        // after successfully update configuration invoke checkoutjs
                        window.Paytm.CheckoutJS.invoke();
                    })
                    .catch((error) => {
                    });
            } else {
                enqueueSnackbar("Can't Initialize Paytm", {variant: 'error'});
            }
        });
    };

    const saveBillInfo = async (downloadKey) => {
        const body2 = {
            payment_info: BillData[5],
            no_of_orders: BillData[2].Orders,
            username: user?.email ? user.email : localUser,
        };
        const body = {
            username: user?.email ? user.email : localUser,
            GstNo: BillData[3].Gst_No,
            InvoiceDate: BillData[4],
            Amount: BillData[2].DiscountedPrice,
            PaymentInfo: body2,
            FormName: BillData[3].Business_Name,
            OrderId: BillData[1],
            DownloadKey: downloadKey,
            EnvType: envType,
            isCouponUsed,
            action: 'saveBillInfo',
        };
        if (isCouponUsed) {
            body.couponCode = couponCode;
            body.userId = userIdForValidation;
            body.offerDate = offerDate;
        }

        const response = await CreatePostAPICall(enqueueSnackbar, UserProfile, body);
        if (response) {
            getCoupons();
            onResetCouponState();
            setPaymentData(GetPaymentOptions);
            enqueueSnackbar('Success.');
        }
    };

    const uploadBill = (blob, url, fileName) => {
        if (url) {
            const a = document.createElement('a');
            a.download = fileName;
            a.href = url;
            a.addEventListener('click', () => {
                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
            });
            a.click();
            const file = new File([blob], `${fileName}.pdf`);
            uploadFileS3(
                file,
                `${fileName}.pdf`,
                `${envType}/${user?.email}/${BillData[1]}/${fileName}.pdf`,
                BILL_BUCKET,
                enqueueSnackbar('Bill Uploaded Successfully')
            );
            saveBillInfo(`${envType}/${user?.email}/${BillData[1]}/${fileName}.pdf`);
        }
    };

    const handleOnSelectPaymentOption = (Id) => {
        const checkedData = paymentData.map((x) => {
            x.IsChecked = x.Id === Id;
            return x;
        });
        setPaymentData(checkedData);
    };

    const onclickCouponSelectionBtn = (coupon) => {
        // console.log("coupon",coupon,"GetPaymentOptions:",GetPaymentOptions,"couponCode",couponCode);
        let tempData = [...GetPaymentOptions];
        if (coupon.couponCode === couponCode) {
            tempData = tempData.map((obj, i) => ({
                ...obj,
                OfferOnDiscountPrice: 0,
            }));
            onResetCouponState();
        } else {
            const newOfferDate = coupon.sort_key.split('#');
            setOfferDate(newOfferDate[2]);
            setIsCouponUsed(true);
            setCouponAmount(coupon.amountOrPercentage);
            setCouponType(coupon.offerType);
            setCouponCode(coupon.couponCode);

            if (coupon.OfferOn !== 'all') {
                const priceIndex = tempData.findIndex((price) => price.Price === Number(coupon.OfferOn));
                if (priceIndex > -1) {
                    tempData.forEach((element, index) => {
                        if (element.Price === Number(coupon.OfferOn)) {
                            element.OfferOnDiscountPrice = Number(coupon.amountOrPercentage);
                        } else {
                            element.OfferOnDiscountPrice = 0;
                        }
                    });
                }
            } else {
                tempData = tempData.map((obj, i) => ({
                    ...obj,
                    OfferOnDiscountPrice: Number(coupon.amountOrPercentage),
                }));
            }

            tempData.forEach((element) => {
                if (coupon.offerType === 'PERCENTAGE_OFF') {
                    element.finalDiscountedPrice =
                        element.DiscountedPrice - (element.DiscountedPrice * element.OfferOnDiscountPrice) / 100;
                }

                if (coupon.offerType === 'FLAT_OFF') {
                    element.finalDiscountedPrice = element.DiscountedPrice - element.OfferOnDiscountPrice;
                }
                element.finalGST = element.finalDiscountedPrice + (element.finalDiscountedPrice * 18) / 100;
                element.FinalInWords = `Indian rupee ${toWords(element.finalGST)}`;
            });
        }
        setPaymentData(tempData);
    };

    const getCoupons = async () => {
        const payloadForUserWiseCoupon = {
            action: 'userWiseCoupon',
            username: user?.email ? user.email : localUser,
        };
        const response = await CreatePostAPICall(enqueueSnackbar, UserProfile, payloadForUserWiseCoupon);
        if (response) {
            setUserCoupon(response.data.body);
        }
    };

    useEffect(() => {
        getCoupons();
    }, []);

    return (
      <Grid item xs={12} md={12}>
        {loading ? (
          <Card sx={{ width: '100%', textAlign: 'center', height: '30rem', paddingTop: '20%' }}>
            <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
            <br />
            Please Wait We Redirecting You To Payment Page...
          </Card>
        ) : (
          <>
            <Card sx={{ p: 3 }}>
              {/* TODO Comment Or Uncomment Div Container For Offer Text And Edit And Edit Into Utils(utilities.js GOTO 36 Number Line) Also */}
              <div style={{ color: '#21AE8C', marginLeft: '10px', marginBottom: '10px', fontWeight: 'bold' }}>
                {/* Dear Customers, Our business has always benefited from your contribution as a buyer. To us, you are like family. Hopefully, our relationship
                       with you will remain the same in the New Financial Year. Our dear customer, have a wonderful New Financial Year And, Hurry Up And Recharge With Our New Financial Offer With Some Discount On All Packages, Offer Ends On 10th April 2023. */}
                {/* Dear Customers, Hurry Up And Recharge With Our New Shri Ram Pran Pratishtha Festival Offer With 5% Discount On All Packages, Offer Ends On 23<sup>rd</sup> January 2024. */}
              </div>

              {userCoupon.length > 0 && (
                <>
                  <Button
                    onClick={() => {
                      setShowApplyCoupon(true);
                    }}
                    style={{ marginLeft: '10px', marginBottom: '10px' }}
                  >
                    {' '}
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <Iconify
                        icon={'mdi:tag'}
                        width={20}
                        height={20}
                        style={{ marginTop: '5px', marginRight: '10px' }}
                      />{' '}
                      <h3>Apply Coupons</h3>{' '}
                    </div>{' '}
                  </Button>
                </>
              )}

              <Scrollbar>
                <TableContainer sx={{ minWidth: 720 }}>
                  <Table>
                    <TableHead>
                      <TableRow key={'a2'}>
                        <TableCell sx={{ minWidth: 20 }} align="center">
                          Select
                        </TableCell>
                        <TableCell sx={{ minWidth: 30 }} align="center">
                          Price
                        </TableCell>
                        <TableCell sx={{ minWidth: 40 }} align="center">
                          Discounted Price
                        </TableCell>
                        <TableCell sx={{ minWidth: 30 }} align="center">
                          Orders
                        </TableCell>
                        <TableCell sx={{ minWidth: 30 }} align="center">
                          Per Order Cost
                        </TableCell>
                        <TableCell sx={{ minWidth: 40 }} align="center">
                          Price with GST
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentData &&
                        paymentData?.map((payment, index) => (
                          <>
                            <TableRow
                              onClick={() => handleOnSelectPaymentOption(payment.Id)}
                              style={{ cursor: 'pointer' }}
                              key={index}
                            >
                              <TableCell sx={{ m: 0, p: 0.5 }} align="center">
                                <Checkbox
                                  checked={payment.IsChecked}
                                  onChange={() => handleOnSelectPaymentOption(payment.Id)}
                                />
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0.5 }} align="center">
                                <h5>{payment.Price}</h5>
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0.5 }} align="center">
                                <div>
                                  <h5
                                    style={{
                                      color: payment.OfferOnDiscountPrice > 0 ? SystemColor.error : '',
                                      display: 'inline-block',
                                      textDecoration: payment.OfferOnDiscountPrice > 0 ? 'line-through' : '',
                                    }}
                                  >
                                    {payment.DiscountedPrice}
                                  </h5>

                                  {couponType === 'PERCENTAGE_OFF' && payment.OfferOnDiscountPrice > 0 ? (
                                    <>
                                      <h5
                                        style={{
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        (-{payment.OfferOnDiscountPrice}%)
                                      </h5>
                                      <h5
                                        style={{
                                          marginLeft: '10px',
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        {/* {payment.finalDiscountedPrice} */}
                                        {payment.DiscountedPrice -
                                          (payment.DiscountedPrice * payment.OfferOnDiscountPrice) / 100}
                                      </h5>
                                    </>
                                  ) : null}
                                  {couponType === 'FLAT_OFF' && payment.OfferOnDiscountPrice > 0 ? (
                                    <>
                                      <h5
                                        style={{
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        (-{payment.OfferOnDiscountPrice}₹)
                                      </h5>
                                      <h5
                                        style={{
                                          marginLeft: '10px',
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        {payment.finalDiscountedPrice}
                                      </h5>
                                    </>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0.5 }} align="center">
                                <h5>{payment.Orders}</h5>
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0.5 }} align="center">
                                <h5
                                  style={{
                                    color: payment.OfferOnDiscountPrice > 0 ? SystemColor.error : '',
                                    display: 'inline-block',
                                    textDecoration: payment.OfferOnDiscountPrice > 0 ? 'line-through' : '',
                                  }}
                                >
                                  {payment.PerOrderCost}
                                </h5>
                                {couponType === 'PERCENTAGE_OFF' && payment.OfferOnDiscountPrice > 0 ? (
                                  <>
                                    <h5
                                      style={{
                                        color: SystemColor.success,
                                        display: 'inline-block',
                                      }}
                                    >
                                      (-{payment.OfferOnDiscountPrice}%)
                                    </h5>
                                    <h5
                                      style={{
                                        marginLeft: '10px',
                                        color: SystemColor.success,
                                        display: 'inline-block',
                                      }}
                                    >
                                      {(
                                        payment.PerOrderCost -
                                        (payment.PerOrderCost * payment.OfferOnDiscountPrice) / 100
                                      ).toFixed(2)}
                                    </h5>
                                  </>
                                ) : null}

                                {couponType === 'FLAT_OFF' && payment.OfferOnDiscountPrice > 0 ? (
                                  <>
                                    <h5
                                      style={{
                                        marginLeft: '10px',
                                        color: SystemColor.success,
                                        display: 'inline-block',
                                      }}
                                    >
                                      {(
                                        (payment.DiscountedPrice - payment.OfferOnDiscountPrice) /
                                        payment.Orders
                                      ).toFixed(2)}
                                    </h5>
                                  </>
                                ) : null}
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0.5 }} align="center">
                                <div>
                                  <h5
                                    style={{
                                      color: payment.OfferOnDiscountPrice > 0 ? SystemColor.error : '',
                                      display: 'inline-block',
                                      textDecoration: payment.OfferOnDiscountPrice > 0 ? 'line-through' : '',
                                    }}
                                  >
                                    {payment.PriceWithGST}
                                  </h5>
                                  {couponType === 'PERCENTAGE_OFF' && payment.OfferOnDiscountPrice > 0 ? (
                                    <>
                                      <h5
                                        style={{
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        (-{payment.OfferOnDiscountPrice}%)
                                      </h5>
                                      <h5
                                        style={{
                                          marginLeft: '10px',
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        {/* {payment.finalGST} */}
                                        {payment.PriceWithGST -
                                          (payment.PriceWithGST * payment.OfferOnDiscountPrice) / 100}
                                      </h5>
                                    </>
                                  ) : null}
                                  {couponType === 'FLAT_OFF' && payment.OfferOnDiscountPrice > 0 ? (
                                    <>
                                      <h5
                                        style={{
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        (-{payment.OfferOnDiscountPrice}₹)
                                      </h5>
                                      <h5
                                        style={{
                                          marginLeft: '10px',
                                          color: SystemColor.success,
                                          display: 'inline-block',
                                        }}
                                      >
                                        {payment.finalGST}
                                      </h5>
                                    </>
                                  ) : null}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow key={payment.Price + 3}>
                              <TableCell sx={{ m: 0, p: 0 }} colSpan={'100%'} align="center">
                                <Divider />
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <div style={{ paddingLeft: '12px', paddingTop: '10px', fontSize: '12px' }}>
                <span style={{ color: 'red' }}>
                  {' '}
                  <strong>*Please note:</strong>{' '}
                </span>{' '}
                All payments are final and non-refundable. Your account will be disabled, and available orders will
                expire if the account is inactive for 90 days.
              </div>

              <div style={{ paddingLeft: '12px', paddingTop: '10px' }}>
                <Checkbox
                  checked={istermsAndConditionCheck}
                  onChange={() => {
                    setIstermsAndConditionCheck(!istermsAndConditionCheck);
                  }}
                />
                I Agree To The BuissMaster OMS{' '}
                <a
                  href="https://www.buissmaster.com/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: SystemColor.success, textDecoration: 'none' }}
                >
                  Terms & Conditons
                </a>{' '}
                And{' '}
                <a
                  href="https://www.buissmaster.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: SystemColor.success, textDecoration: 'none' }}
                >
                  Privacy Policy.
                </a>
              </div>

              <LoadingButton
                disabled={!istermsAndConditionCheck}
                sx={{
                  mt: 2,
                  ml: 2.5,
                }}
                type="submit"
                variant="contained"
                onClick={onClickPayment}
              >
                Pay Now
              </LoadingButton>
            </Card>
          </>
        )}

        {showBillModel ? (
          <>
            <BootstrapDialog
              sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
              maxWidth="sm"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={showBillModel}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Download Bill
              </BootstrapDialogTitle>
              <Divider orientation="horizontal" flexItem />
              <DialogContent>
                <>
                  <PDFDownloadLink
                    sx={{ textDecoration: 'none' }}
                    document={
                      <Invoice
                        no_of_orders={BillData[2]?.Orders}
                        orderId={BillData[1]}
                        invoiceDate={BillData[4]}
                        formName={BillData[3]?.Business_Name}
                        address={BillData[3]?.Address}
                        gstNo={BillData[3]?.Gst_No}
                        amount={
                          BillData[2]?.OfferOnDiscountPrice > 0
                            ? BillData[2]?.finalDiscountedPrice
                            : BillData[2]?.DiscountedPrice
                        }
                        InWords={
                          BillData[2]?.OfferOnDiscountPrice > 0 ? BillData[2]?.FinalInWords : BillData[2]?.InWords
                        }
                        CouponCode={couponCode}
                        CouponType={couponType === 'PERCENTAGE_OFF' ? '%' : '₹'}
                        CouponAmount={couponAmount}
                        isCouponUsed={isCouponUsed}
                      />
                    }
                    fileName={BillData[1]}
                  >
                    {({ blob, url, loading }) =>
                      loading ? (
                        <div style={{ paddingLeft: '20px' }}>
                          <Button variant="contained">Generating Bill...</Button>
                        </div>
                      ) : (
                        <div style={{ paddingLeft: '20px' }}>
                          <Button variant="contained" onClick={handleClose}>
                            Download Your Gst Bill
                          </Button>
                          {uploadBill(blob, url, BillData[1])}
                        </div>
                      )
                    }
                  </PDFDownloadLink>
                </>
              </DialogContent>
            </BootstrapDialog>
          </>
        ) : null}

        <BootstrapDialog
          sx={{ '& .MuiDialog-paper': { width: '100%' } }}
          maxWidth="xs"
          onClose={handleCloseApplyCoupon}
          aria-labelledby="customized-dialog-title"
          open={showApplyCoupon}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseApplyCoupon}>
            Apply Coupons
          </BootstrapDialogTitle>
          <Divider orientation="horizontal" flexItem />
          <DialogContent>
            <Grid sx={{ p: 2, paddingLeft: '30px' }} container spacing={2}>
              {userCoupon.map((coupon, index) => {
                if (coupon.userId) {
                  return (
                    <>
                      <Grid sx={{ p: 2, display: 'block' }} container spacing={3}>
                        <Button
                          style={{ border: coupon.couponCode !== couponCode && '1px dashed' }}
                          variant={coupon.couponCode === couponCode && 'contained'}
                          onClick={() => {
                            if (coupon.couponStatus === 'Live') {
                              onclickCouponSelectionBtn(coupon);
                            }
                          }}
                        >
                          {coupon.couponCode}
                        </Button>
                        <div style={{ paddingTop: '5px', fontSize: '16px', fontWeight: '700' }}>
                          Save {coupon.amountOrPercentage}
                          {coupon.offerType === 'PERCENTAGE_OFF' ? '%' : '₹'}
                        </div>
                        <div style={{ fontSize: '14px' }}>
                          Offer On: {coupon.OfferOn === 'all' ? 'All' : coupon.OfferOn} | Expires on:{' '}
                          {moment(coupon.expiryDateTime).format('DD/MM/YYYY')}
                        </div>
                        <Divider
                          sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            borderStyle: 'dashed',
                            borderWidth: '0.5px',
                          }}
                        />
                      </Grid>
                    </>
                  );
                }
                return <div />;
              })}
            </Grid>
          </DialogContent>
          <Divider orientation="horizontal" flexItem />
          <DialogActions>
            <Button variant="contained" onClick={handleCloseApplyCoupon}>
              Apply Coupon
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Grid>
    );
}
